<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.category.title')"
        :sub-title="$t('settings.category.desc')"
        :btn-title="$t('settings.category.new_btn')"
        :btn="true"
        variant="main"
        @btnAction="showModal = true;categoryData=null"
    />


    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <div class="w-table d-flex flex-column settings-categories">
            <div class=" settings-categories-grid">
              <div v-for="category in getAllCategories"
                   :key="category.id"
                   @click="showModal = true; categoryData = category"
                   class="category-card">
                {{category.title}}
              </div>
            </div>
            <wameed-no-data
                v-if="getAllCategories && getAllCategories.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <wameed-pagination
              v-if="getTotalCategories"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalCategories.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
      <category-modal
          :visible="showModal"
          @close="showModal = false"
          @onComplete="()=>loadData()"
          :category="categoryData"
      ></category-modal>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import CategoryModal from "@/views/pages/settings/categories/components/categoryModal";

export default {
  components: {
    CategoryModal,
    WameedPagination,
    WameedNoData,
    PageHeader,

  },
  data() {
    return {
      categoryData: null,
      showModal:false,
      filterData: {
        page: 1,
        per_page: 20,
      },
    }
  },
  computed: {
    ...mapGetters({
      getAllCategories: 'getAllCategories',
      getTotalCategories: 'getTotalCategories',
    }),
  },

  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadCategories: 'loadCategories',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadCategories(this.filterData);
    },


  },
};
</script>
