<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        size="lg"
        content-class="package-modal rounded-14 mx-4"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ category != null ? $t('settings.category.update_btn') : $t('settings.category.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="12">
          <text-input
              id="package-ad"
              v-model="form.name"
              :label="$t('settings.category.form.name')"
              :placeholder="$t('settings.category.form.name_placeholder')"
              name="package-ad"
              rules="required"
          />
        </b-col>

      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-10"
            :title="category!=null ?$t('common.update'):$t('common.add')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @submitAction="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-10 "
            :title="$t('common.cancel')"
            type="button"
            variant="gray"
            @submitAction="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import WameedBtn from '@/components/WameedBtn.vue';
import TextInput from '@/components/TextInput.vue';
import {mapActions} from 'vuex';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    category: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      form: {
        name: '',
      },
      showSuccessModal: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    category(newVal) {
      console.log(newVal);
      if (newVal != null) {
        this.form.name = newVal.title;
      }else{
        this.form.name = '';
      }
    },
  },
  created() {
    if (this.category != null) {
      this.form.name = this.category.name;
    }
  },
  methods: {
    ...mapActions(['addCategory', 'updateCategory']),
    submitOrder() {
      if (this.category == null) {
        this.addCategory(this.form).then(() => {
          this.$emit('onComplete', true);
          this.show = false;
          this.showModal = true;
          this.form.name = '';
          this.$store.commit('loadingFinish');
        });
      } else {
        this.updateCategory({
          id: this.category.id,
          name: this.form.name,
        }).then(() => {
          this.$emit('onComplete', true);
          this.show = false;
          this.showModal = true;
          this.form.name = '';
          this.$store.commit('loadingFinish');
        });
      }
    },
    closeModal() {
      this.show = false;
    },
  },
};
</script>
